// Action Imports
import { ADD_SERVER_FILE, GET_SERVER_FILE_LIST } from "../Actions/Types";

// Defining Initial State
const initialState = {
  serverFileList: [],
};

// Defining Reducer Function
export const serverFileState = (state = initialState, action) => {
  // Destructuring Action
  const { type, payload } = action;

  switch (type) {
    // Add Server File Case
    case ADD_SERVER_FILE: {
      return {
        state,
      };
    }

    // Server File List Case
    case GET_SERVER_FILE_LIST:
      return {
        ...state,
        serverFileList: payload,
      };

    // Default Case
    default: {
      return state;
    }
  }
};
