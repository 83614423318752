import React, { useState } from "react";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

export const tabStyle = {
  // justifyContent: "flex-start",
  textTransform: "none",
  fontSize: "1rem",
  color: "white",
  borderRadius: "4px",
  height: "3em",
  paddingTop: "0.8em",
  paddingLeft: "0.8em",
  minHeight: "3em",
  marginTop: "0.5em",
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "#0E1A2F",
  },
};

function DashBoard() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("");

  const handleTabClick = (route) => {
    navigate(route);
    setSelectedTab(route);
  };

  return (
    <Grid
      container
      height="100vh"
      width="100%"
      backgroundColor="rgb(31, 42, 64)"
      boxShadow="0px 3px 6px #00000029"
    >
      <Grid item xs={12} height="13%" paddingLeft="10%" paddingTop="10%">
        <Typography sx={{ color: "white", fontSize: "2em" }}>
          Deployment WebApp
        </Typography>
      </Grid>
      <Grid
        xs={12}
        item
        container
        height="75%"
        display="flex"
        alignItems="center"
      >
        <Grid item container width="100%" height="100%">
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Tabs
              sx={{ width: "100%" }}
              orientation="vertical"
              indicatorColor="none"
            >
              <Tab
                label="Server Files"
                onClick={() => handleTabClick("/serverfiles")}
                sx={{
                  marginTop: "1em",

                  fontSize: "1rem",
                  color: "#FFFFFF",
                  textTransform: "none",
                  width: "100%",
                  backgroundColor:
                    selectedTab === "/serverfiles" ? "#050F32" : undefined,
                }}
              />
              <Tab
                label="Server List"
                onClick={() => handleTabClick("/serverlist")}
                sx={{
                  marginTop: "1em",
                  fontSize: "1rem",
                  color: "#FFFFFF",
                  textTransform: "none",
                  width: "100%",
                  backgroundColor:
                    selectedTab === "/serverlist" ? "#050F32" : undefined,
                }}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashBoard;
