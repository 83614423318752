import { BASE_API } from "../GlobalVariable";
import axios from "axios";

//#region Add Server File Service
export const addServerFileService = async (formData) => {
  try {
    // Url
    const url = `${BASE_API}/server-file/generate-server-setup-file`;

    // Header
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = {
      serverName: formData.server,
      port: parseInt(formData.port),
      ec2Name: formData.ec2instance,
    };

    // Get Result
    const result = await axios.post(url, data, config);

    // Prmoise Resolved
    return result.data.result;
  } catch (err) {
    return err.message;
  }
};

//#region Server File List Service
export const getServerFileListService = async () => {
  try {
    // URL
    const url = `${BASE_API}/server-file/server-list`;

    // Header Config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Get Server File List
    const result = await axios.get(url, config);

    // Promise Resolve
    return result.data.result;
  } catch (err) {
    return Promise.reject(err);
  }
};

//#endregion
