// Package Imports
import React from "react";
import { Routes, Route } from "react-router-dom";

// View Imports
import ServerFilesView from "../Views/ServerFilesView";
import ServerFileListView from "../Views/ServerFileListView";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/serverfiles" element={<ServerFilesView />} />
      <Route path="/serverlist" element={<ServerFileListView />} />
    </Routes>
  );
};

export default AuthRoutes;
