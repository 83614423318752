// Package Imports
import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Grid } from "@mui/material";
import "./App.css";
// Component Imports

import Alert from "./Components/Common/Alert";
import DashBoard from "./Views/DashBoard";

// Route Imports
import ServerFileRouter from "./Routes/ServerFileRoutes";

const App = () => {
  return (
    <Router>
      <Fragment>
        <Alert />
        <Grid container height="100%" width="100%">
          <Grid item width="20%">
            <DashBoard />
          </Grid>
          <Grid item width="80%" backgroundColor="rgb(20, 27, 45)">
            <ServerFileRouter />
          </Grid>
        </Grid>
      </Fragment>
    </Router>
  );
};

export default App;
