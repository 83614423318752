// Package Import
import { v4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './Types';

export const setAlert =
    (msg, alertType, timeout = 5000) =>
    (dispatch) => {
        const id = v4();
        dispatch({
            type: SET_ALERT,
            payload: {
                id,
                alertType,
                msg,
            },
        });

        setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
    };
