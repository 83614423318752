import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";

// Material UI Imports
import Alert from "@mui/material/Alert";

const CustomAlert = ({ alerts }) => {
  if (alerts !== null && alerts.length > 0) {
    return alerts.map((alert) => (
      <Alert variant="filled" severity={alert.alertType} key={alert.id}>
        {alert.msg}
      </Alert>
    ));
  }
};

// Defining Proptype
CustomAlert.prototype = {
  alerts: PropTypes.array.isRequired,
};

// Access Redux State
const mapStateToProps = (state) => ({
  alerts: state.alertState,
});

export default connect(mapStateToProps)(CustomAlert);
