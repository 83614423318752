// Service Imports
import {
  addServerFileService,
  getServerFileListService,
} from "../../Services/ServerFileService";

// Type Imports
import { ADD_SERVER_FILE, GET_SERVER_FILE_LIST } from "../Actions/Types";
import { setAlert } from "./alertAction";

//#region  Add Server File Action
export const addServerFileAction = (formData) => async (dispatch) => {
  try {
    // Adding Server File
    const result = await addServerFileService(formData);

    // Add Sucess Alert
    dispatch(setAlert("Server File Added Successfully", "success", 3000));

    // Dispatching To Reducer
    dispatch({ type: ADD_SERVER_FILE, payload: result });
  } catch (err) {
    let errors = err.response.data.errors;
    if (errors !== undefined) {
      for (let x of errors) {
        dispatch(setAlert(x.msg, "error", 3000));
      }
    } else {
      return Promise.reject(err);
    }
  }
};

//#endregion

//#region  Server File List Action
export const serverFileListAction = () => async (dispatch) => {
  try {
    // Get Server File List
    const result = await getServerFileListService();

    // Dispatching To Reducer
    dispatch({ type: GET_SERVER_FILE_LIST, payload: result });

    // Reosolve Promise
    return Promise.resolve();
  } catch (err) {
    let errors = err.response.data.errors;
    if (errors !== undefined) {
      for (let x of errors) {
        dispatch(setAlert(x.msg, "error", 3000));
      }
    } else {
      return Promise.reject(err);
    }
  }
};
//#endregion
