import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { serverFileListAction } from "../Redux/Actions/ServerFileAction";

// #region  Data Grid Style
export const dataGridStyle = {
  color: "white",
  boxShadow: 2,
  border: "2px solid #3DA58A",

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#3E4396",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#1F2A40",
  },
  "& .MuiButtonBase-root": {
    color: "white",
    backgroundColor: "#3DA58A",
    "&:hover": {
      backgroundColor: "#3DA58A",
    },
  },
  "& .columnHeaderRow": {
    backgroundColor: "red !important",
  },
  "& .MuiDataGrid-toolbarContainer": {
    padding: "6px",
  },
  "& .MuiDataGrid-main": {
    padding: "4px",
  },
};

// #endregion

function ServerFileListView({ serverFileListAction, serverFileList }) {
  // State for Data Grid Row
  const [row, setRow] = useState([]);

  // #region Setting Columns for Data Grid
  const columns = [
    { field: "col1", headerName: "Server Name", width: 300 },
    { field: "col2", headerName: "Port", width: 280 },
    { field: "col3", headerName: "EC2 Name", width: 300 },
  ];
  // #endregion

  useEffect(() => {
    serverFileListAction();
  }, [serverFileListAction]);

  // #region Create Rows For Data Grid
  useEffect(() => {
    // Create Row
    let rows = [];
    // Adding To Rows
    if (serverFileList && Symbol.iterator in Object(serverFileList)) {
      for (let x of serverFileList) {
        // Creating new Object
        let rowObject = {
          id: x.serverId,
          col1: x.name || "Not Provided",
          col2: x.port || "Not Provided",
          col3: x.ec2Name || "Not Provided",
        };
        // Push Data
        rows.push(rowObject);
      }
      // Set Row State
      setRow(rows);
    }
  }, [serverFileList]);
  // #endregion
  return (
    <>
      <Box
        sx={{ width: "95%", overflow: "auto", margin: "auto", marginTop: "3%" }}
      >
        <div>
          <DataGrid
            rows={row}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={dataGridStyle}
          />
        </div>
      </Box>
    </>
  );
}

// Declare the expected prop types for the component
ServerFileListView.propTypes = {
  serverFileList: PropTypes.array.isRequired,
  serverFileListAction: PropTypes.func.isRequired,
};

// Map the state values to props
const mapStateToProps = (state) => ({
  serverFileList: state.serverFileState.serverFileList,
});

// Connect the component to the Redux store and export it
export default connect(mapStateToProps, { serverFileListAction })(
  ServerFileListView
);
