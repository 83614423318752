import { Grid, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Action Import
import { addServerFileAction } from "../Redux/Actions/ServerFileAction";

function ServerFilesView({ addServerFileAction }) {
  const [formData, setFormData] = useState({
    port: "",
    server: "",
    ec2instance: "",
  });

  const handleChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if any field is empty
    if (
      formData.port === "" ||
      formData.server === "" ||
      formData.ec2instance === ""
    ) {
      return;
    }

    addServerFileAction(formData);

    // Reset the form after submission
    setFormData({
      port: "",
      server: "",
      ec2instance: "",
    });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: "#1F2A40",
        width: "50%",
        height: "50%",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        margin: "auto",
        marginTop: "15%",
        borderRadius: "3%",
      }}
    >
      <Grid container direction="column" spacing={2} alignItems="center">
        <Grid item sx={{ width: "60%" }} alignItems="center">
          <TextField
            style={{
              width: "100%",
              color: "white",
            }}
            label="Port"
            name="port"
            value={formData.port}
            onChange={handleChange}
            type="number"
            InputProps={{
              style: {
                backgroundColor: "rgb(20, 27, 45)",
                height: "50px",
                textAlign: "center",
                borderRadius: "5px",
                fontSize: "15px",
                color: "white",
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
          />
        </Grid>
        <Grid item sx={{ width: "60%" }} alignItems="center">
          <TextField
            style={{
              width: "100%",
              color: "white",
            }}
            label="Server"
            name="server"
            value={formData.server}
            onChange={handleChange}
            InputProps={{
              style: {
                backgroundColor: "rgb(20, 27, 45)",
                height: "50px",
                textAlign: "center",
                borderRadius: "5px",
                fontSize: "15px",
                color: "white",
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
          />
        </Grid>
        <Grid item sx={{ width: "60%" }} alignItems="center">
          <TextField
            style={{
              width: "100%",
              color: "white",
            }}
            label="EC2 Instance"
            name="ec2instance"
            value={formData.ec2instance}
            onChange={handleChange}
            InputProps={{
              style: {
                backgroundColor: "rgb(20, 27, 45)",
                height: "50px",
                textAlign: "center",
                borderRadius: "5px",
                fontSize: "15px",
                color: "white",
              },
            }}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              marginTop: "1em",
              backgroundColor: "rgb(76, 206, 172)",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "rgb(76, 206, 172)",
              },
            }}
            onClick={handleSubmit}
            disabled={
              formData.port === "" ||
              formData.server === "" ||
              formData.ec2instance === ""
            }
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

ServerFilesView.propTypes = {
  addServerFileAction: PropTypes.func.isRequired,
};

export default connect(null, { addServerFileAction })(ServerFilesView);
