export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// Authentication
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAIL = "REGISTRATION_FAIL";

// Server File Types

export const ADD_SERVER_FILE = "ADD_SERVER_FILE";
export const GET_SERVER_FILE_LIST = "GET_SERVER_FILE_LIST";
